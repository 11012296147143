//Guest
export const apiGetAllTopicGuest = "Guest/GetAllTopic"
export const apiGetByRegionId = "Guest/GetByRegionId"
export const apiGetListBannerFooterEvent = "Guest/GetListBannerFooterEvent"
export const apiGetListEvent = "Guest/GetListEventGuest"
export const apiGetPostHome = "Guest/GetPostHome"
export const apiGetListDep = "Guest/GetListDepartment"
export const apiAddUserRepresentative = "Guest/AddUserRepresentative"

export const apiGetListGuestIndividual = "Guest/GetListGuestIndividual"
export const apiGetRegion = "Guest/GetByRegionId"
export const apiUpdateGuest = "Guest/UpdateGuest"
export const apiGetDocAttach = "Guest/GetDocAttach"
export const apiGetListRepresentative = "Guest/GetListRepresentative"
export const apiGetListDept = "Guest/GetList"
export const apiGetDetailByNumberCode = "Guest/GetDetailByNumberCode"
export const apiUploadFile = "Guest/UploadFile"
