import QueryString from "qs"
import http from "../index"
import {
  apiAddUserRepresentative,
  apiGetAllTopicGuest,
  apiGetByRegionId,
  apiGetDocAttach,
  apiGetListBannerFooterEvent,
  apiGetListDep,
  apiGetListDept,
  apiGetListEvent,
  apiGetListGuestIndividual,
  apiGetListRepresentative,
  apiGetPostHome,
  apiGetRegion,
  apiUpdateGuest,
  apiGetDetailByNumberCode,
  apiUploadFile,
} from "./urls"
const getAllTopic = () => http.post(apiGetAllTopicGuest)
const getByRegionId = params => http.get(apiGetByRegionId, { params })
const getListBanner = () => http.get(apiGetListBannerFooterEvent)
const getListEvent = () => http.get(apiGetListEvent)
const GetPostHome = () => http.get(apiGetPostHome)
const getListDep = body =>
  http.get(apiGetListDep + `?${QueryString.stringify(body)}`)

const getListGuestIndividual = body =>
  http.post(apiGetListGuestIndividual, body)

const getListRegion = params => http.get(apiGetRegion, { params })
const getListDept = params => http.get(apiGetListDept, { params })
const getDetailByNumberCode = params =>
  http.get(apiGetDetailByNumberCode, { params })

const updateGuest = body => http.put(apiUpdateGuest, body)
const getDocAttach = body => http.post(apiGetDocAttach, body)
const addUserRepresentative = body => http.post(apiAddUserRepresentative, body)
const getListRepresentative = body => http.post(apiGetListRepresentative, body)
const uploadFile = body => http.post(apiUploadFile, body)

const GuestServices = {
  getAllTopic,
  getByRegionId,
  getListBanner,
  getListEvent,
  GetPostHome,
  getListDep,
  getListGuestIndividual,
  getListRegion,
  updateGuest,
  getDocAttach,
  addUserRepresentative,
  getListRepresentative,
  getListDept,
  getDetailByNumberCode,
  uploadFile,
}
export default GuestServices
