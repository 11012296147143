export const apiGetAll = `DossierCate/GetAll`
export const apiInsert = `DossierCate/Insert`
export const apiUpdate = `DossierCate/Update`
export const apiDelete = `DossierCate/Delete`
export const apiGetDocAttach = `DossierCate/GetDocAttach`
export const apiInsertDocAttach = `DossierCate/InsertDocAttach`
export const apiUpdateDocAttach = `DossierCate/UpdateDocAttach`
export const apiDeleteDocAttach = `DossierCate/DeleteDocAttach`
export const apiGetFeeAttach = `DossierCate/GetFeeAttach`
export const apiInsertFeeAttach = `DossierCate/InsertFeeAttach`
export const apiUpdateFeeAttach = `DossierCate/UpdateFeeAttach`
export const apiDeleteFeeAttach = `DossierCate/DeleteFeeAttach`
export const apiGetListItem = `DossierCate/GetListItem`
export const apiInsertItem = `DossierCate/InsertItem`
export const apiUpdateItem = `DossierCate/UpdateItem`
export const apiDeleteItem = `DossierCate/DeleteItem`
export const apiGetListField = `DossierCate/GetListField`
export const apiGetById = `DossierCate/GetById`
export const apiGetCapSoDonVM = `DossierCate/GetCapSoDon`
export const apiUpdateCapSoDonVM = `DossierCate/UpdateCapSoDon`
export const apiGetListDossierCateItem = "DossierCate/GetListDossierCateItem"
export const apiInsertCapSoDon = "DossierCate/InsertCapSoDon"
export const apiDeleteCapSoSon = "DossierCate/DeleteCapSoSon"
export const apiGetDossierCateBySingleField =
  "DossierCate/GetDossierCateBySingleField"
export const apiGetDossierType = "DossierCate/GetDossierType"

export const apiGetAllDossierCate = "DossierCate/GetAllDossierCate"
