import {
  apiGetAll,
  apiInsert,
  apiUpdate,
  apiDelete,
  apiGetDocAttach,
  apiInsertDocAttach,
  apiUpdateDocAttach,
  apiDeleteDocAttach,
  apiGetFeeAttach,
  apiInsertFeeAttach,
  apiUpdateFeeAttach,
  apiDeleteFeeAttach,
  apiGetListItem,
  apiInsertItem,
  apiUpdateItem,
  apiDeleteItem,
  apiGetListField,
  apiGetById,
  apiUpdateCapSoDonVM,
  apiGetCapSoDonVM,
  apiGetListDossierCateItem,
  apiInsertCapSoDon,
  apiDeleteCapSoSon,
  apiGetDossierCateBySingleField,
  apiGetDossierType,
  apiGetAllDossierCate,
} from "./urls"
import http from "../index"
import QueryString from "qs"

const getAll = body => http.post(apiGetAll, body)
const insertDossier = body => http.post(apiInsert, body)
const updateDossier = body => http.post(apiUpdate, body)
const deleteDossier = body =>
  http.patch(apiDelete + `?${QueryString.stringify(body)}`)
const getDocAttach = body => http.post(apiGetDocAttach, body)
const insertDocAttach = body => http.post(apiInsertDocAttach, body)
const updateDocAttach = body => http.post(apiUpdateDocAttach, body)
const deleteDocAttach = body =>
  http.delete(apiDeleteDocAttach + `?${QueryString.stringify(body)}`)
const getFeeAttach = body => http.post(apiGetFeeAttach, body)
const insertFeeAttach = body => http.post(apiInsertFeeAttach, body)
const updateFeeAttach = body => http.put(apiUpdateFeeAttach, body)
const deleteFeeAttach = body =>
  http.delete(apiDeleteFeeAttach + `?${QueryString.stringify(body)}`)
const getListItem = body => http.post(apiGetListItem, body)
const insertItem = body => http.post(apiInsertItem, body)
const updateItem = body => http.put(apiUpdateItem, body)
const deleteItem = body =>
  http.delete(apiDeleteItem + `?${QueryString.stringify(body)}`)
const getListField = params => http.get(apiGetListField, { params })
const getById = id => http.get(apiGetById + `/${id}`)
const insertCapSoDon = body => http.post(apiInsertCapSoDon, body)
const updateCapSoDonVM = body => http.post(apiUpdateCapSoDonVM, body)
const getCapSoDonVM = params => http.get(apiGetCapSoDonVM, { params })
const getDossierCateBySingleField = params =>
  http.get(apiGetDossierCateBySingleField, { params })
const getDossierCateBySingleField2 = params =>
  http.get(apiGetDossierCateBySingleField + `?${QueryString.stringify(params)}`)
const getListDossierCateItem = body =>
  http.post(apiGetListDossierCateItem, body)
const deleteCapSoSon = body =>
  http.patch(apiDeleteCapSoSon + `?${QueryString.stringify(body)}`)
const getDossierType = body =>
  http.get(apiGetDossierType + `?${QueryString.stringify(body)}`)

const getAllDossierCate = () => http.get(apiGetAllDossierCate)
const DossierCateService = {
  getAll,
  getAllDossierCate,
  getDossierType,
  insertDossier,
  updateDossier,
  deleteDossier,
  getDocAttach,
  insertDocAttach,
  updateDocAttach,
  deleteDocAttach,
  getFeeAttach,
  insertFeeAttach,
  updateFeeAttach,
  deleteFeeAttach,
  getListItem,
  insertItem,
  updateItem,
  deleteItem,
  getListField,
  getById,
  updateCapSoDonVM,
  insertCapSoDon,
  getCapSoDonVM,
  getListDossierCateItem,
  deleteCapSoSon,
  getDossierCateBySingleField,
  getDossierCateBySingleField2,
}
export default DossierCateService
